.contacts {
  .MuiFormControl-root.MuiTextField-root {
    margin: 0
  }
  
  .MuiTableCell-root.MuiTableCell-head > div {
    transform: rotate(-90deg)
  }

  .MuiTableCell-root.MuiTableCell-body {
    padding-left: 2px;
    padding-right: 2px;
  }

  .MuiFormControl-root.MuiTextField-root {
    &:not(div[class*=".MTableToolbar-searchField-"]) {
      width: 100%
    }
  }
}
