html {
 height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #efeff4;
  height: 100%
}

#root {
  height: 100%
}
